<template>
  <b-card title="Vincular novo usuário a área de trabalho" class="text-center text-primary pt-1">
    <b-row class="justify-content-center d-flex mb-5">
      <b-col cols="6">
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          placeholder="Selecione um usuário"
          v-model="userSelected"
          :options="users"
          :getOptionLabel="item => `${item.username} (${item.email})`"
          :searchable="true"
          @search="
            (search, loading) => {
              onFiltered(search);
            }
          "
        />
      </b-col>
      <b-col cols="8" class="mt-2">
        <h4 for="contact" class="text-center font-weight-bold text-primary pb-1">Permissões</h4>
        <b-row class="justify-content-start d-flex p-1">
          <b-col cols="6">
            <b-form-checkbox
              :disabled="!userSelected"
              v-model="modelUserPermission.is_admin"
              name="checkbox-1"
              value="true"
              switch
              class="mb-1"
              unchecked-value="false"
            >
              Admin
            </b-form-checkbox>
            <b-form-checkbox
              :disabled="!userSelected"
              v-model="modelUserPermission.is_manager"
              name="checkbox-1"
              class="mb-1"
              switch
              value="true"
              unchecked-value="false"
            >
              Gerente
            </b-form-checkbox>
          </b-col>
          <b-col cols="6">
            <b-form-checkbox
              :disabled="!userSelected"
              v-model="modelUserPermission.is_transaction_manager"
              name="checkbox-1"
              class="mb-1"
              switch
              value="true"
              unchecked-value="false"
            >
              financeiro
            </b-form-checkbox>
            <b-form-checkbox
              :disabled="!userSelected"
              v-model="modelUserPermission.is_blocked"
              name="checkbox-1"
              switch
              class="mb-1"
              value="true"
              unchecked-value="false"
            >
              Inativo
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-alert v-if="errors" show variant="danger">
          <p class="text-center">
            <br />
            Você precisa selecionar pelo menos um <strong>permissão</strong> de acesso para o usuário!
          </p>

          <hr />
        </b-alert>
      </b-col>
    </b-row>
    <hr class="invoice-spacing mb-3" />
    <b-row class="d-flex justify-content-between p-1">
      <b-col class="justify-content-between d-flex">
        <!-- back -->
        <b-button variant="warning" class="mr-2" :disabled="loading" @click="goBack">Voltar</b-button>

        <!-- saveWorkspace -->
        <b-button :disabled="!userSelected" variant="primary" @click="saveUserPermisions">
          <b-spinner v-if="loading" label="Loading..."></b-spinner>
          <span v-if="!loading">Salvar</span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BCard, BRow, BCol, BFormCheckbox, BButton, BAlert } from 'bootstrap-vue';
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BAlert
  },
  data() {
    return {
      modelUserPermission: {
        is_admin: false,
        is_blocked: false,
        is_manager: false,
        is_transaction_manager: false
      },
      userSelected: null,
      users: [],
      loading: false,
      errors: false
    };
  },
  created() {
    this.getAllUsersNotVinculated();
  },
  methods: {
    getAllUsersNotVinculated() {
      this.$store
        .dispatch('getAllUsersNotVinculated', {
          id: this.$route.params.workspace_id
        })
        .then(resp => {
          this.users = resp;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao buscar usuários',
              icon: 'XIcon',
              variant: 'danger'
            }
          });
        });
    },
    saveUserPermisions() {
      this.errors = false;
      if (
        this.modelUserPermission.is_admin ||
        this.modelUserPermission.is_blocked ||
        this.modelUserPermission.is_manager ||
        this.modelUserPermission.is_transaction_manager
      ) {
        let body = {
          ...this.modelUserPermission,
          user: this.userSelected,
          workspace_id: this.$route.params.workspace_id
        };
        this.$store
          .dispatch('saveUserPermission', body)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'permissões de usuário salvas com sucesso',
                icon: 'SuccessIcon',
                variant: 'success'
              }
            });
            this.$router.push(`/workspaces-users/${this.$route.params.workspace_id}`);
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro ao salvar permissões',
                icon: 'XIcon',
                variant: 'danger'
              }
            });
          });
      } else {
        this.errors = true;
      }
    },
    goBack() {
        this.$router.go(-1);
      }
  }
};
</script>

<style></style>
